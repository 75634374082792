<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">设备详情</div>
      <div @click="unBind()" class="header-left"><img src="@/accesst/index/un-bind.png" alt="" srcset="">&nbsp;解绑</div>
    </div>
    <div class="h85"></div>
    <div class="device-header">{{item.deviceName || '眼保仪'}} SN：{{ item.deviceSn }}</div>
    <div class="device-img-bg">
      <!-- <img v-if="!item.deviceStatus" src="@/accesst/device/off.png" class="device-img-status" alt="" srcset="" /> -->
      <!-- <img v-else src="@/accesst/device/on.png" class="device-img-status" alt="" srcset="" /> -->
      <van-image v-if="src" class="device-img" :src="src">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <van-loading style="position: absolute; top: 3rem;left: 3.3rem" type="spinner" v-else size="20" />
    </div>
    <div class="h35"></div>
    <div class="divce-cell">
      <img src="@/accesst/device/off.png" class="divce-cell-img" alt="" srcset="">
      <div>设备状态：</div>
      <div class="divce-cell-status" :class="{'color-on': item.deviceStatus}">{{ item.deviceStatus? '在线' : '关机' }}</div>
    </div>
    <!-- <div class="h35"></div>
    <div class="divce-cell">
      <img src="@/accesst/device/code.png" class="divce-cell-img" alt="" srcset="">
      <div>设备编码：</div>
      <div class="divce-cell-status">{{ item.deviceSn }}</div>  
    </div> -->
    <div class="h35"></div>
    <div class="divce-cell">
      <img src="@/accesst/device/view.png" class="divce-cell-img" alt="" srcset="">
      <div>正在使用：</div>
      <!-- <div class="divce-cell-status">{{item.nowBrowse || '-'}} {{ item.nowBrowse ? ('浏览 ' + item.totalUseTime +' 分钟') : '' }}</div>   -->
      <div class="divce-cell-status">{{item.nowBrowse || '-'}}</div>  
    </div>
    <div class="h30"></div>
    <div class="divce-cell">
      <img src="@/accesst/device/user.png" class="divce-cell-img" alt="" srcset="" style="padding-top: .12rem;"/>
      <div style="padding-top: .12rem;">当前用户：<span style="color:#333">{{ item.nowUser || '-' }}</span></div>
      <!-- <div class="user-list"> -->
        <!-- <div class="user-item">{{ item.nowUser || '-' }}</div> -->
        <!-- <div @click="hrefUserList(u)" v-for="u in item.deviceUsers" :key="u.id" :class="{'active': u.userId === item.nowUserNo}" class="user-item">{{ u.userName }}</div> -->
        <!-- <div @click="hrefUserList()" class="user-item active">张三张三张三</div> -->
      <!-- </div> -->
    </div>
    <div class="h30"></div>
    <div v-show="item.deviceStatus" class="divce-cell">
      <img src="@/accesst/device/code.png" class="divce-cell-img" alt="" srcset="">
      <div>设备操作：</div>
      <div class="cell-btns">
        <div @click="onInput" class="cel-btn active">关机</div>
        <div @click="shot" class="cel-btn active">截图</div>
      </div>      
    </div>
  </div>  
</template>

<script>
import { Icon,  Image as VanImage, Loading } from 'vant';
import { Dialog } from 'vant';
import { devicePic, delDev, deviceOff } from '@/api/h5';
import devPic from '@/accesst/index/dev-default.png';
export default {
  components: { [Icon.name]: Icon, [VanImage.name]: VanImage, [Loading.name]: Loading },
  data() {
    return {
      src: '',
      item: {},
      interval: ''
    }
  },
  methods: {
    unBind(){
      const id = this.item.id;
      Dialog.confirm({
        message: '你确定解绑此设备？',
      }).then((v) => {        
        if(v === 'confirm') {
          delDev({id}).then(res => {
            this.$toast('解绑成功');
            this.$router.push({path: '/'})
          })
        } 
      });      
    },
    onInput() {
      console.log(this.item)      
      Dialog.confirm({
        title: '提示',
        message: '您确认要关闭此设备吗？',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            setTimeout(done, 1000);
            deviceOff({deviceSn: this.item.deviceSn}).then(r => {
              this.$toast('关机成功');
              item.deviceStatus = false;
              item.nowUser = ''
              item.nowBrowse = ''
              this.src = devPic;
            });
          } else {
            done();
          }
        }
      })
    },
    hrefUserList(item) {
    //  item = {
    //     "id": 20,
    //     "userNo": "1",
    //     "userName": "张三",
    //     "sex": 0,
    //     "birthday": "2019-08-24",
    //     "height": "",
    //     "weight": "",
    //     "isGlasses": "",
    //     "relationship": "",
    //     "status": 2,
    //     "createTime": "2019-08-24T14:15:22Z"
    //   }
      this.$router.push({path: '/userList', query: { item: JSON.stringify(item), deviceName: this.item.deviceName || '眼保仪' }});
    },
    loadDevPic(deviceSn) {
      devicePic({deviceSn}).then(res=> {
        console.log(res , 'pic res')
        const _t = this;
        let count = 0;
        this.interval = setInterval(() => {      
          count ++
          var img = new Image();
          img.src = res;
          img.onload = function() {
            console.log('succcess')
            _t.src = res;
            clearInterval(_t.interval)
          }
          img.onerror = function() {        
            if(count > 50) {
              clearInterval(_t.interval);
              _t.src= devPic;
              _t.count = 0
            }
          }
        }, 1000)
        
      }).catch(e => {
        console.log(e, 'pic err')
      })

    },
    shot() {
      this.src = '';
      this.interval && clearInterval(this.interval)
      this.loadDevPic(this.item.deviceSn);
    }
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval)
  },
  created() {
    const item = JSON.parse(this.$route.query.item)
    console.log(item, 'item')
    this.item = item;
    if(item.deviceStatus) { // 在线请求设备截图
      this.loadDevPic(item.deviceSn);
    } else {
      this.src = devPic;
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  .header-left {
    display: flex;
    align-items: center;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: .27rem;
    color: #2B67D9;
    line-height: .4rem;
    text-align: left;
    font-style: normal;
    img {
      width: .3rem;
    }
  }
  .cell-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .2rem;
    .cel-btn {
      padding: 0 .3rem;
      font-size: .27rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      line-height: .62rem;
      height: .62rem;
      background: #F6F8F9;
      border-radius: .08rem;
    }
    .cel-btn.active {
      background: #2B67D9;
      color: #FFF
    }
  }
  .h35 {
    height: .35rem;
  }
  .h30 {
    height: .3rem;
  }
  .divce-cell-user-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: .27rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    padding: 0 .3rem;
    .user-list {
      width: 5.01rem;
      display: flex;
      flex-wrap: wrap;
      gap: .24rem;
      .user-item {
        padding: 0 .3rem;
        font-size: .27rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
        // line-height: .62rem;
        // height: .62rem;
        // background: #F6F8F9;
        border-radius: .08rem;
      }
      .user-item.active {
        background: #2B67D9;
        color: #FFF
      }
    }
  }
  .divce-cell-img {
    width: .32rem;
    height: .32rem;
    margin-right: .08rem;
  }
  .divce-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .27rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    padding: 0 .3rem;
    
    .divce-cell-status {
      font-size: .27rem;
      font-family: PingFangSC, PingFang SC;
      color: #000;
      font-weight: 600;
    }
    .color-on {
      color: #00AD81
    }
    .color-off {
      color: #AD0000
    }
    .color-leave {
      color: #666666;
    }
  }
  .device-header {
    padding: .35rem .3rem;
    font-size: .27rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
  .device-img-bg {
    width: 6.9rem;
    margin: auto;
    display: block;
    height: 6.9rem;
    border-radius: .12rem;
    position: relative;
    // background: url('~@/accesst/goods/bg.png') no-repeat;
    background: #EEE;
    background-size: 6.9rem 4.15rem;
    .device-img-status {
      position: absolute;
      top: .33rem;
      right: .38rem;
      width: .46rem;
      z-index: 2;
    }
    .device-img {
      position: absolute;
      top: .0rem;
      right: .0rem;
      width: 6.9rem;            
      height: 6.9rem;
      border-radius: .12rem;
      z-index: 1;
    }
  }
  .h85 {
    height: .85rem;
  }
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      // width: .3rem;
    }
  }
}
</style>